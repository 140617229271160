import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Input, Modal, Switch, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { showErrorNotification } from "src/util/notifications";
import { ImportType } from "src/util/utils";
import Label from "../Label/Label";
import { useMsal } from "@azure/msal-react";

const { Dragger } = Upload;

export const UploadModal = ({
  visible,
  onCancel,
  bulkUpload,
  stateKey,
  importFile,
  isCsv = false,
  params,
  importType,
  confirmLoading,
  setConfirmLoading,
}: any) => {
  const dispatch = useDispatch();
  const isImportLoading = useSelector(
    (state: any) => state[stateKey]
  ).importLoading;

  const { accounts }: any = useMsal();
  const [open, setOpen] = useState(false);
  const [fileOptions, seFileOptions] = useState();
  const [messageText, setMessageText] = useState("");
  const [assortmentName, setAssortmentName] = useState("");
  const [requiredError, setRequiredError] = useState(false);
  const [specialCharError, setSpecialCharError] = useState(false);
  const [planogramOpen, setPlanogramOpen] = useState(false);
  const [createAssortment, setCreateAssortment] = useState(false);

  const handleChanges = (options: any) => {
    setMessageText("");
    const format = options?.file?.name?.split(".").pop();
    if (isCsv ? format === "csv" : format === "xlsx") {
      if (options?.file?.status !== "removed") {
        seFileOptions(options.file);
        importType === ImportType.PlanogramImport && !bulkUpload
          ? setPlanogramOpen(true)
          : bulkUpload
          ? setOpen(bulkUpload)
          : setOpen(true);
      }
    } else {
      showErrorNotification(
        "Incorrect file format. Only " +
          (isCsv ? ".csv" : ".xlsx") +
          " files are supported."
      );
    }
  };

  const handleOk = () => {
    setConfirmLoading(true);
    dispatch(
      importFile({
        file: fileOptions,
        params: params,
        UserName: accounts?.[0]?.username,
      })
    );
    setOpen(false);
    setConfirmLoading(false);
    setTimeout(() => onCancel(), 10);
  };

  const handleCancel = () => {
    setOpen(false);
    setTimeout(() => onCancel(), 10);
  };

  const planogramImport = () => {
    setConfirmLoading(true);
    if (createAssortment && assortmentName.length == 0) {
      setRequiredError(true);
      setConfirmLoading(false);
      handleCancel();
    } else if (!specialCharError && !specialCharError) {
      setRequiredError(false);
      setSpecialCharError(false);
      dispatch(
        importFile({
          file: fileOptions,
          params: {
            assortmentName: assortmentName,
            isAssortment: createAssortment,
            userName: accounts?.[0]?.username,
            IsBulkUpdate: bulkUpload,
          },
        })
      );
      handleCancel();
      setAssortmentName("");
      setPlanogramOpen(false);
      setTimeout(() => onCancel(), 10);
      setCreateAssortment(false);
      setConfirmLoading(false);
    }
  };

  const handlePlanogramCancel = () => {
    setAssortmentName("");
    setPlanogramOpen(false);
    setTimeout(() => onCancel(), 10);
  };

  const handleClose = () => {
    setMessageText("");
    onCancel();
  };

  return (
    <StyledImportModal
      title="Upload File"
      open={visible}
      onCancel={handleClose}
      footer={null}
      centered
    >
      <Dragger
        disabled={isImportLoading}
        accept={".xlsx"}
        maxCount={1}
        beforeUpload={() => false}
        fileList={[]}
        onChange={handleChanges}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        {isImportLoading ? (
          <p className="ant-upload-hint">Processing...</p>
        ) : (
          <>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for a single file only</p>
            {/* <span className={styles["ant-upload-error"]}>{messageText}</span> */}
          </>
        )}
      </Dragger>
      <Modal
        title="Upload Confirmation"
        okText="Ok"
        centered
        style={{ top: 20 }}
        open={open}
        onOk={!bulkUpload ? handleOk : planogramImport}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
      >
        <span>
          {!bulkUpload
            ? "Are you sure you want to upload this file?"
            : "Are you sure you want to perform this bulk update?"}
        </span>
      </Modal>

      <Modal
        title="Upload Confirmation"
        centered
        style={{ top: 20 }}
        open={planogramOpen}
        onCancel={handlePlanogramCancel}
        confirmLoading={confirmLoading}
        footer={[
          <Button key="yes" type="primary" onClick={() => planogramImport()}>
            OK
          </Button>,
        ]}
      >
        <div>
          <div>
            <Label
              style={{ color: "black" }}
              label="Do you want to create new assortment for these items?"
              className="flex-row align-items-center fs-6 gap-3"
            >
              <Switch
                size="small"
                checked={createAssortment}
                onChange={(flag) => {
                  setAssortmentName("");
                  setCreateAssortment(flag);
                }}
              />
            </Label>
          </div>
          {createAssortment && (
            <div>
              <span>Enter Assortment name</span>
              <Input
                value={assortmentName}
                onChange={(e) => {
                  setAssortmentName(e.target.value);
                  if (e.target.value.length != 0 && requiredError)
                    setRequiredError(false);
                  const regex = new RegExp(/^[- a-zA-Z0-9]+$/);
                  if (
                    !regex.test(e.target.value) &&
                    e.target.value.length > 0
                  ) {
                    setSpecialCharError(true);
                  } else {
                    setSpecialCharError(false);
                  }
                }}
                maxLength={100}
                name="AssortmentName"
              />
              {requiredError && (
                <span style={{ color: "red", fontSize: 11 }}>
                  Please enter assortment name
                </span>
              )}
              {specialCharError && (
                <span style={{ color: "red", fontSize: 11 }}>
                  Assortment Name should be alphanumeric
                </span>
              )}
            </div>
          )}
        </div>
      </Modal>
    </StyledImportModal>
  );
};

const StyledImportModal = styled(Modal)`
  .ant-modal-content {
    width: initial;
  }
`;
