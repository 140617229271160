import { reactApp } from "./common.constants";

export const BASE_URL = `${reactApp().apiUrl}`;

export const PRINT_TAGS_URL = {
  OFFICES: "office/v1/offices",
  ALLOFFICES: "office/v1/alloffices",
  REQUEST_TAG: "distribution/v1/printtagrequest",
  ARCHIVE_TAG: "distribution/v1/archivetagrequest",
  UPDATE_TAG: "distribution/v1/printtagstatusbycolor",
};

export const OPEN_PULL_URL = {
  REQUEST_PULL: "distribution/v1/openpull",
  LOAD_ORDER: "distribution/v1/Loadorderpick",
};

export const FRAME_PICK_URL = {
  REQUEST_FRAME: "distribution/v1/framepick",
  REQUEST_PICKER: "distribution/v1/picker",
  UPRATE_ACUITY: "distribution/v1/updateacuityorderstatus",
  PRINT_LABEL: "distribution/v1/printlabelstatus",
  GENERATE_REPORT: "distribution/v1/generatepdfreport",
  REPRINT_LABELS: "distribution/v1/reprintlabel",
};

export const VENDOR_ORDER_URL = {
  VENDOR_ORDER_LIST: "distribution/v1/vendororder",
  VENDOR_ORDER_ITEMS: "distribution/v1/vendororder/",
  SAVE_ORDER_ITEMS: "distribution/v1/vendororder/details",
  CANCEL_ORDER_ITEMS: "distribution/v1/vendororder/cancel",
  PENDING_ORDER_LIST: "distribution/v1/backorderpending",
};

export const ORDER_STATUS_URL = {
  ORDER_STATUS_LIST: "distribution/v1/orders",
  SAVE_ORDER_ITEMS: "distribution/v1/vendororder/details",
};

export const DAILY_REPORT_URL = {
  DAILY_REPORT_LIST: "distribution/v1/orders/daily",
  UPDATE_ORDER_STATUS: "distribution/v1/orders/status",
  VENDOR_EXLUSION_LIST: "distribution/v1/vendorexclusionlist",
  SAVE_VENDOR_EXLUSION: "distribution/v1/vendorexclusion/update",
};

export const VENDOR_URL = {
  VENDOR_LIST: "distribution/v1/Vendors",
  VENDOR_PICKER_ASSIGNMENT: "distribution/v1/vendorpickerassignment",
  CREATE_UPDATE_PICKER: "distribution/v1/picker",
};

export const REPLENISHMENT_URL = {
  REPLENISHMENT_LIST: "distribution/v1/replenishmentOrders",
  REPLENISHMENT_APPROVE: "distribution/v1/replenishmentorderapproval",
  REPLENISHMENT_REFRESH: "distribution/v1/replenishmentrefresh",
  REPLENISHMENT_VENDOR_LIST: "distribution/v1/replenishmentVendors",
  OVERRIDE_ORDER_QTY_URL: "distribution/v1/replenishmentorder",
};

export const FEATURE_FLAG_URL = {
  DC_REPLENISHMENT_ENABLED: "util/v1/featureflag/DC_ReplenishmentEnabled",
  DC_VIEW_ORDERS_ENABLED: "util/v1/featureflag/DC_ViewOrdersEnabled",
  DC_KITS_MANAGEMENT_ENABLED: "util/v1/featureflag/DC_KitsManagementEnabled",
};

export const ACCESSORY_REPLENISHMENT_URL = {
  ACCESSORY_REPLENISHMENT_LIST: "distribution/v1/systempreferences",
  PREFERENCES: "distribution/v1/skuconfig",
  GET_PRODUCT_CATEGORY_LIST: "distribution/v1/itemgroup",
  GET_PRODUCT_LIST: "distribution/v1/item",
};
export const KITS_MAINTENANCE_URL = {
  KITS_MAINTENANCE: "distribution/v1/kit",
  KITS_MAINTENANCE_LIST: "distribution/v1/kits/",
  KITS_MAINTENANCE_DETAILS: "distribution/v1/kit/",
  CHECK_UPC_VENDOR_COLLECTION: "distribution/v1/kit/checkupcvendorcollection",
  COLLECTION_BY_VENDOR: "distribution/v1/collectionbyvendor/",
  VENDOR_LIST_WITH_KITS: "distribution/v1/GetVendorsWithKits",
  DELETE_KIT: "distribution/v1/kit/delete",
  ARCHIVE_KIT: "distribution/v1/kit/Archive",
};

export const KITS_PROCESSING_URL = {
  KITS_PROCESSING_LIST: "distribution/v1/ProcessKit/",
  KITS_PROCESSING_DETAILS: "distribution/v1/kit/",
  PRE_POPULATED_OFFICES: "distribution/v1/kit/prepopulatedoffices/",
  SELECTED_OFFICES: "distribution/v1/selectedOffices",
  KIT_BY_VENDOR: "distribution/v1/kit/namelist/",
  VALIDATE_OFFICES: "distribution/v1/kit/quickofficeentry",
};

export const FRAME_ARRANGER_QTY_URL = {
  FRAME_ARRANGER_QTY_LIST: "distribution/v1/Assortments",
  FRAME_ARRANGER_QTY_SAVE: "distribution/v1/FrameCollectionMapping",
  FRAME_TYPE_LIST: "distribution/v1/frametypes",
  DATABASE_COLLECTION_LIST: "distribution/v1/vendortocollectionlisting",
  EDIT_FA_QTY: "distribution/v1/FrameCollectionMapping/",
  FRAME_ARRANGER_LIST_FOR_OFFICE: "distribution/v1/framearrangers/",
  ASSORTMENT_SKU: "distribution/v1/assortment/getframes/",
  VALIDATE_SKU: "distribution/v1/validateupcforassortment",
  IMPORT_SKU_VALIDATION: "distribution/v1/validateupcforimportassortment/",
  ASSORTMENT_COUNT: "distribution/v1/AssortmentCount/",
  ASSORTMENT_DELETE: "distribution/v1/DeleteAssortment",
  ASSORTMENT_IMPORT: "distribution/v1/ImportAssortment/",
};
export const VENDOR_COLLECTION_URL = {
  VENDOR_COLLECTION_LIST: "distribution/v1/vendortocollectionlisting",
  VENDOR_COLLECTION_SAVE: "distribution/v1/vendortocollectionlisting",
  COLLECTION_LIST: "distribution/v1/framecollections",
  EDIT_VC: "distribution/v1/vendortocollectionlisting/",
};

export const PRODUCT_PLANOGRAM_URL = {
  PRODUCT_PLANOGRAM_LIST: "distribution/v1/getplanogramdata",
  PRODUCT_PLANOGRAM_UPDATE_INSERT: "distribution/v1/productplanogram",
  PRODUCT_PLANOGRAM_IMPORT_FILE: "distribution/v1/importproductplanograms",
  PRODUCT_PLANOGRAM_BY_ID: "distribution/v1/productplanogram/",
  VALIDATE_ACUITY_FRAME_IMPORT: "distribution/v1/productimportacuityvalidation",
  DELETE_PP_ITEM: "distribution/v1/deleteproductplanogarm/",
  GET_ASSORTMENTS: "distribution/v1/assortmentNames/",
};

export const OFFICE_COLLECTION_URL = {
  OFFICE_COLLECTION_LIST: "distribution/v1/officeCollectionStock",
  OFFICE_COLLECTION_SAVE: "distribution/v1/OfficeCollectionMapping",
  COLLECTION_TYPE_LIST: "distribution/v1/collectionTypes",
  EDIT_OC: "distribution/v1/OfficeCollectionMapping/",
  BULK_EDIT: "distribution/v1/OfficeCollectionMapping/bulkedit",
  CLOSE_OC: "distribution/v1/closeoffice",
};

export const FEDEX_URL = {
  SHIPPING_STATUS: "distribution/v1/dailyshipping",
  CREATE_SHIPPING_URL: "distribution/v1/CreateFedExShipment",
  REPRINT_URL: "distribution/v1/RePrintShipmentLabel",
};
export const EXPRESS_OFFICE_URL = {
  EXPRESS_OFFICE_LIST: "distribution/v1/ListShipmentPreference",
  DELETE_OFFICE: "distribution/v1/DeleteShipmentPreference",
  ADD_OFFFICE: "distribution/v1/AddShipmentPreference",
};
export const FQDN = {
  PRINT_LABEL_FORM:
    "http://medreports.myeyedr.corp/reports/report/Distribution/LabelFormNTEST",
};

export default {
  OPEN_PULL_URL,
  PRINT_TAGS_URL,
  FRAME_PICK_URL,
  VENDOR_URL,
  REPLENISHMENT_URL,
  VENDOR_ORDER_URL,
};
