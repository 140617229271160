import { DatePicker, Drawer, Form, Input, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "src/components/common/Button/Button";
import { getAllOffices } from "src/components/systemPreference/sagas";
import { DATE_FORMAT } from "src/util/columns";
import dayjs from "dayjs";
import { select } from "redux-saga/effects";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import { closeOffice } from "./sagas";

interface CloseOffice {
  visibleClose: boolean;
  setVisibleClose: any;
  userName: any;
  onLoad: any;
}

function CloseOffice({
  visibleClose,
  setVisibleClose,
  userName,
  onLoad,
}: CloseOffice) {
  const { Option } = Select;
  const [frameDetailForm] = Form.useForm();
  const [locationData, setLocationData] = useState([]);
  const [stopDate, setStopDate] = useState<any>(null);

  useEffect(() => {
    const location: any = [];
    Promise.all([getAllOffices({})]).then((res) => {
      const sortedOffice: any = res[0]?.sort((a: any, b: any) => {
        return (
          a.LocationNumber !== null &&
          a.LocationNumber.localeCompare(b.LocationNumber, "en", {
            numeric: true,
          })
        );
      });
      sortedOffice.map((item: any) => {
        location.push({
          LocationNumber: item?.LocationNumber,
          LocationName: item?.LocationName,
        });
      });

      setLocationData(location);
    });
  }, []);

  const handleClose = () => {
    setVisibleClose(false);
    frameDetailForm.resetFields();
  };

  const handleOk = async () => {
    try {
      const row = (await frameDetailForm.validateFields()) as any;
      const fields = frameDetailForm.getFieldsValue();
      let res;

      let payload: any = {
        Office: fields?.Office,
        StopDate: fields?.StopDate.format(DATE_FORMAT),
        UserName: userName,
        Notes: fields?.Notes,
      };

      res = await closeOffice(payload);
      if (res) {
        showSuccessNotification(res);
        handleClose();
        onLoad();
      } else {
        showErrorNotification(res);
      }
      setStopDate(null);
      frameDetailForm.resetFields();
    } catch (error: any) {
      if (error.response?.data?.error?.messages) {
        showErrorNotification(error.response.data.error.messages[0]);
      } else showErrorNotification("Something went wrong");
    }
  };

  const handleAllData = async (key: any, value: any) => {
    if (value?.length == 0) {
      frameDetailForm.resetFields(["Offices"]);
    }
  };

  return (
    <>
      <Drawer
        title="Close office"
        placement="right"
        open={visibleClose}
        onClose={handleClose}
        width={600}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button danger onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleOk}>Save</Button>
          </Space>
        }
      >
        <div>
          <Form
            form={frameDetailForm}
            initialValues={{ remember: false }}
            autoComplete="off"
            className="add-picker-form"
            layout="vertical"
            name="frameDetailForm"
            style={{
              backgroundColor: "#f3f3f3",
              borderRadius: "16px",
              marginRight: "24px",
              boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              marginTop: "10px",
            }}
          >
            <div>
              <Form.Item
                label="Office"
                name="Office"
                className="label-text region-field"
                rules={[{ required: true, message: "Please select Office" }]}
              >
                <Select
                  showSearch={true}
                  allowClear={true}
                  placeholder="Select Offices"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option!.children?.[0] !== null
                      ? (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      : false;
                  }}
                  optionLabelProp="label"
                  maxTagCount="responsive"
                  onChange={(value) => handleAllData("office", value)}
                >
                  {locationData?.map((item: any, index) => {
                    return (
                      <Option key={index} value={item.LocationNumber}>
                        {item.LocationNumber}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Stop Date"
                name="StopDate"
                className="label-text region-field"
                rules={[{ required: true, message: "Please select stop date" }]}
              >
                <DatePicker
                  format={DATE_FORMAT}
                  name="StopDate"
                  value={stopDate && dayjs(stopDate)}
                  disabledDate={(d: any) => d.isBefore(dayjs().add(-1, "days"))}
                  placeholder="Select Date"
                  onChange={(value: any) =>
                    setStopDate(dayjs(value).format(DATE_FORMAT))
                  }
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Changed Notes"
                name="Notes"
                className="label-text region-field"
              >
                <Input name="Notes" />
              </Form.Item>
            </div>
          </Form>
        </div>
      </Drawer>
    </>
  );
}

export default CloseOffice;
