import { Divider, Form, Modal, Popconfirm, Spin } from "antd";
import { MODULE_KEY, fetchVendorCollectionMapping } from "./actions";
import GenericTable from "src/components/common/Table/GenericTable";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import { PlusOutlined } from "@ant-design/icons";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useState } from "react";
import filterColumns from "./filterColumns";
import "./../../../styles/vendor.scss";
import { useDispatch } from "react-redux";
import { getFADetail } from "./sagas";
import AddVCMapping from "./AddVCMapping";
import useRolePermission from "src/hooks/useRolePermission";

function VendorsAndCollection() {
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [visible, setVisible] = useState(false);
  const { hasPermission } = useRolePermission();
  useEffect(() => {
    dispatch(fetchVendorCollectionMapping({}));
  }, []);
  const addDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(true);
  };
  const editDrawer = (record: any) => {
    let payload;
    payload = [record?.AcuityCollectionID];
    setVisible(true);
    const res = getFADetail(payload);
    res.then((response: any) => {
      setModalData(response);
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setModalData({});
    dispatch(fetchVendorCollectionMapping({}));
  };

  let filterValues = filterColumns(editDrawer);

  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Vendor to Collection Mapping</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            {hasPermission("vendorscollectionsmapping_add") && (
              <Button
                size="middle"
                icon={<PlusOutlined />}
                style={{ marginRight: "10px" }}
                ant-click-animating-without-extra-node="true"
                onClick={addDrawer}
              >
                {" "}
                Add New
              </Button>
            )}
          </div>
        </TableFilter>
        <GenericTable
          rowkey="AcuityCollectionID"
          columns={filterValues.columns}
          moduleKey={MODULE_KEY}
          loading={confirmLoading}
          xScroll={1000}
          yScroll={"calc(90vh - 17.5em)"}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
          }}
        />{" "}
        <AddVCMapping
          visible={visible}
          setVisibleStatus={setVisible}
          onLoad={handleCancel}
          data={modalData}
          setData={setModalData}
        />
      </Spin>
    </>
  );
}
export default VendorsAndCollection;
