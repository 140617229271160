import { Card, DatePicker, Form, Input, Select } from "antd";
//import dayjs from "dayjs";
import { DATE_FORMAT } from "src/util/columns";
import { useCallback } from "react";
import { createFedExShipping } from "./sagas";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { fetchFedEx } from "./actions";
import Button from "src/components/common/Button/Button";
import dayjs from "dayjs";
import useRolePermission from "src/hooks/useRolePermission";

function FedExForm({
  form,
  shippingDate,
  setShippingDate,
  shippingStatus,
  shippingOpenDate,
  accounts,
  setConfirmLoading,
  searchOffice,
  setSearchOffice,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  officeData,
}: any) {
  const { hasPermission } = useRolePermission();
  const dispatch = useDispatch();
  const { Option } = Select;
  const dateRequiredValidation =
    shippingDate === "Invalid date"
      ? { required: true, message: "Please select date" }
      : { required: false };

  const createFedEx = async (barcodeValue: any, date: any) => {
    setConfirmLoading(true);
    const payload = {
      officeNumber: barcodeValue,
      shippingDate: date.substring(0, 10),
      createdBy: accounts[0]?.username,
    };
    await createFedExShipping(payload)
      .then((res: any) => {
        showSuccessNotification(res);
        form.setFieldValue("OfficeBarcode", "");
        dispatch(
          fetchFedEx({
            ShippingDate: date.substring(0, 10) || null,
            StartDate: null,
            EndDate: null,
            OfficeNumber: null,
          })
        );
        setConfirmLoading(false);
      })
      .catch((error: any) => {
        showErrorNotification(error?.response?.data?.error?.messages[0]);
        setConfirmLoading(false);
      });
  };

  const searchFedex = () => {
    const row = form.validateFields() as any;
    const fields = form.getFieldsValue();
    let payload = {
      ShippingDate: shippingDate?.shippingDate || null,
      StartDate: fields?.startDate
        ? dayjs(fields?.startDate).format(DATE_FORMAT)
        : null,
      EndDate: fields?.endDate
        ? dayjs(fields?.endDate).format(DATE_FORMAT)
        : null,
      OfficeNumber: fields?.office || null,
    };
    if (payload.StartDate && payload.EndDate && payload.OfficeNumber)
      dispatch(fetchFedEx(payload));
  };

  const debounceLoadData = useCallback(debounce(createFedEx, 2000), []);

  const manageOnChange = (value: any, shippingDate: any) => {
    if (value?.length === 4) debounceLoadData(value, shippingDate);
  };

  const resetSearch = () => {
    setSearchOffice(false);
    form.resetFields();
    dispatch(
      fetchFedEx({
        ShippingDate: shippingDate.substring(0, 10) || null,
        StartDate: null,
        EndDate: null,
        OfficeNumber: null,
      })
    );
  };
  return (
    <>
      <Card className="mb-4">
        {shippingOpenDate && (
          <div>
            <div className="d-flex justify-content-end">
              Current shipping date : {shippingOpenDate.substring(0, 10)}
            </div>
          </div>
        )}
        <Form
          form={form}
          autoComplete="off"
          className="add-form"
          layout="vertical"
        >
          {searchOffice ? (
            <>
              <div>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[
                    startDate === "Invalid date" || startDate == null
                      ? { required: true, message: "Please select date" }
                      : { required: true },
                  ]}
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="startDate"
                    value={startDate && dayjs(startDate)}
                    disabledDate={(d) => !d}
                    placeholder="Select Date"
                    onChange={(value: any) =>
                      setStartDate(dayjs(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[
                    endDate === "Invalid date" || endDate == null
                      ? { required: true, message: "Please select date" }
                      : { required: true },
                  ]}
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="endDate"
                    value={endDate && dayjs(endDate)}
                    disabledDate={(d) => !d || d.isBefore(startDate)}
                    placeholder="Select Date"
                    onChange={(value: any) =>
                      setEndDate(dayjs(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Office"
                  name="office"
                  className="label-text region-field"
                  rules={[{ required: true, message: "Please select office" }]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select office"
                    optionFilterProp="children"
                    style={{ width: "100%" }}
                    filterOption={(input, option) =>
                      (option!.children as unknown as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {officeData?.map((item: any, index: any) => {
                      return (
                        <Option key={index} value={item?.LocationNumber}>
                          {item?.LocationNumber}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div
                style={{
                  position: "relative",
                  height: 75,
                }}
              >
                <Button
                  size="middle"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "10%",
                    transform: "translate(-50%, -50%)",
                  }}
                  ant-click-animating-without-extra-node="true"
                  onClick={searchFedex}
                  className="fed-ex-search"
                >
                  Submit
                </Button>

                <Button
                  size="middle"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "32%",
                    transform: "translate(-50%, -50%)",
                  }}
                  ant-click-animating-without-extra-node="true"
                  onClick={resetSearch}
                  className="fed-ex-search"
                >
                  Reset
                </Button>
              </div>
            </>
          ) : (
            <>
              <div>
                <Form.Item
                  label="Shipping Date"
                  name="shippingDate"
                  rules={[dateRequiredValidation]}
                >
                  <DatePicker
                    format={DATE_FORMAT}
                    name="shippingDate"
                    defaultValue={shippingDate && dayjs(shippingDate)}
                    value={shippingDate && dayjs(shippingDate)}
                    disabledDate={(d) => !d}
                    placeholder="Select Date"
                    onChange={(value: any) =>
                      setShippingDate(dayjs(value).format(DATE_FORMAT))
                    }
                  />
                </Form.Item>
              </div>
              {hasPermission("fedex_scanoffice") && (
                <div>
                  <Form.Item
                    label="Office Barcode"
                    name="OfficeBarcode"
                    className=""
                    rules={[
                      {
                        required: true,
                        message: "Please select Office Barcode",
                      },
                      () => ({
                        validator(rule, value) {
                          if (value.length != 4) {
                            return Promise.reject(
                              "Please scan or enter 4 digit office number"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input
                      disabled={!shippingStatus}
                      onChange={(event) =>
                        manageOnChange(event?.target?.value, shippingDate)
                      }
                      maxLength={4}
                    ></Input>
                  </Form.Item>
                </div>
              )}
              <div></div>
            </>
          )}
        </Form>
      </Card>
    </>
  );
}
export default FedExForm;
