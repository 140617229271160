import { call, put } from "redux-saga/effects";
import { default as api } from "../../util/api";
import { FRAME_PICK_URL } from "../../util/constants/api.constants";
import {
  fetchFramePickListFailed,
  fetchFramePickListSuccess,
  fetchPickerSuccess,
} from "./actions";
import { toQueryParam } from "../../util/utils";
import { showErrorNotification } from "src/util/notifications";

export function* fetchFramePick({ payload }: any): Generator {
  try {
    let url = toQueryParam(FRAME_PICK_URL.REQUEST_FRAME, payload);
    const data: any = yield call(api.get, url);
    yield put(fetchFramePickListSuccess(data));
  } catch (e: any) {
    yield put(fetchFramePickListFailed({ message: e.message }));
  }
}

export function* fetchPick({ payload }: any): Generator {
  try {
    let url = FRAME_PICK_URL.REQUEST_PICKER;
    const data: any = yield call(api.get, url);
    yield put(fetchPickerSuccess(data));
  } catch (e: any) {
    yield put(fetchFramePickListFailed({ message: e.message }));
  }
}

export function updateAcuityStatus(payload: any) {
  return api.post(FRAME_PICK_URL.UPRATE_ACUITY, payload);
}

export function updatePrintLabel(payload: any) {
  return api.post(FRAME_PICK_URL.PRINT_LABEL, payload);
}

export function printLabelReport(data: any) {
  try {
    const response: any = api.post(FRAME_PICK_URL.GENERATE_REPORT, data);
    if (response) {
      return response;
    }
  } catch (error: any) {
    showErrorNotification(error?.response?.data?.error?.messages[0]);
  }
}

export function reprintLabelsReport(payload: any) {
  try {
    const data: any = api.post(FRAME_PICK_URL.REPRINT_LABELS, payload);
    return data;
  } catch (error: any) {
    showErrorNotification(error?.response?.data?.error?.messages[0]);
  }
}
