import { call, put } from "redux-saga/effects";
import {
  fetchOfficeCollectionMappingSuccess,
  importFileFailed,
  importFileSuccess,
} from "./actions";
import { showErrorNotification } from "src/util/notifications";
import api from "src/util/api";
import {
  FRAME_ARRANGER_QTY_URL,
  OFFICE_COLLECTION_URL,
  VENDOR_COLLECTION_URL,
} from "src/util/constants/api.constants";

export function* fetchOfficeCollectionMapping({ payload }: any): Generator {
  try {
    let url = OFFICE_COLLECTION_URL.OFFICE_COLLECTION_LIST;
    const data: any = yield call(api.get, url, payload);
    yield put(fetchOfficeCollectionMappingSuccess(data));
  } catch (e: any) {
    showErrorNotification(null, e);
  }
}

export function saveOfficeCollectionMapping(payload: any) {
  let url = `${OFFICE_COLLECTION_URL.OFFICE_COLLECTION_SAVE}`;
  return api.post(url, payload);
}

export function updateOfficeCollectionMapping(payload: any) {
  let url = `${OFFICE_COLLECTION_URL.OFFICE_COLLECTION_SAVE}`;
  return api.put(url, payload);
}

export function bulkEditOfficeColletion(payload: any) {
  let url = `${OFFICE_COLLECTION_URL.BULK_EDIT}`;
  return api.put(url, payload);
}
export function closeOffice(payload: any) {
  let url = `${OFFICE_COLLECTION_URL.CLOSE_OC}`;
  return api.post(url, payload);
}

export function getCollectionType() {
  let url = `${OFFICE_COLLECTION_URL.COLLECTION_TYPE_LIST}`;
  return api.get(url);
}

export function getFADetail(payload: any) {
  let url = OFFICE_COLLECTION_URL.EDIT_OC + payload;
  return api.get(url);
}

export function getFrameCollection(payload: any) {
  let url = `${FRAME_ARRANGER_QTY_URL.FRAME_ARRANGER_QTY_LIST}`;
  return api.post(url, payload);
}

export function getFrameCollectionForOffice(payload: any) {
  let url = FRAME_ARRANGER_QTY_URL.FRAME_ARRANGER_LIST_FOR_OFFICE + payload;
  return api.get(url);
}

export function* importBulkUpdateFile({ payload, actionType }: any): Generator {
  try {
    let url =
      "distribution/v1/ImportOfficeCollectionMapping/" + payload.UserName;
    const formdata = new FormData();
    formdata.append("file", payload.file);
    const data: any = yield call(api.post, url, formdata);
    yield put(importFileSuccess(data));
  } catch (e: any) {
    yield put(importFileFailed(e.response.data));
    //showErrorNotification(e.response, null);
  }
}

export function deleteOPlanogram(payload: any) {
  let url = OFFICE_COLLECTION_URL.OFFICE_COLLECTION_SAVE;
  return api.delete(url, payload);
}
