import {
  showErrorNotification,
  showSuccessNotification,
} from "../../util/notifications";
import { cancelVendorOrderItems } from "./sagas";

export default async function handleCancelOrder(
  userName: any,
  setConfirmLoading: any,
  handleCancel: any,
  selectedRow?: any,
  id?: any,
  itemId?: any
) {
  setConfirmLoading(true);
  try {
    let itemData: { ID: any; ItemID: any }[] = [];
    if (id && itemId) {
      itemData.push({ ID: id, ItemID: itemId });
    } else {
      selectedRow.map((item: any, ind: number) => {
        itemData.push({
          ID: item.ID,
          ItemID: item.ItemID,
        });
      });
    }
    const res = await cancelVendorOrderItems({
      UserName: userName,
      ItemData: itemData,
    });
    if (res) {
      handleCancel();
      showSuccessNotification(res);
    } else {
      showErrorNotification(res);
    }
  } catch (error: any) {
    showErrorNotification(error.response.data.message);
  }
  setConfirmLoading(false);
}
