import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import {
  DATE_TIME_FORMAT,
  KITS_FRAME_COLUMN,
  KITS_MAINTENANCE_COLUMN,
} from "src/util/columns";
import Button from "src/components/common/Button/Button";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  DownSquareOutlined,
  UpSquareOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import useRolePermission from "src/hooks/useRolePermission";

export default function filterColumns(
  deleteModal?: ((record: any) => void) | any,
  editDrawer?: ((record: any) => void) | any,
  filteredData?: any,
  archiveModal?: ((record: any) => void) | any,
  frameData?: any
) {
  const { data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const { hasPermission } = useRolePermission();
  const [records, setRecords] = useState(data);
  useEffect(() => {
    setRecords(data);
  }, [data]);
  useEffect(() => {
    if (filteredData?.length && filteredData[0] !== "none") {
      setRecords(filteredData);
    } else {
      setRecords(data);
    }
  }, [filteredData]);
  const vendors: any | null | undefined = [];
  const kitCollection: any | null | undefined = [];
  records?.map((row: any) => {
    vendors.push({ text: row.Vendor, value: row.Vendor });
    kitCollection.push({ text: row.Collection, value: row.Collection });
  });

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind ===
      vendors.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  let kitCcollectionName = kitCollection.filter(
    (ele: any, ind: any) =>
      ind ===
      kitCollection.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  const vendorColumns = {
    title: "Vendor Name",
    dataIndex: "Vendor",
    sorter: (a: { Vendor: string }, b: { Vendor: any }) =>
      a.Vendor?.localeCompare(b.Vendor),
    sortDirections: ["ascend", "descend"],
    filters: vendorName,
    onFilter: (value: any, record: { Vendor: string | any[] }) =>
      record.Vendor !== null && record.Vendor.indexOf(value) === 0,
    width: 100,
    align: "center",
  };
  let columns = insertAt(KITS_MAINTENANCE_COLUMN, 2, 0, vendorColumns);

  const actionColumn = {
    title: "action",
    dataIndex: "operation",
    // align: "center",
    width: 80,
    render: (_: any, record: any) => {
      return (
        <div className="d-flex">
          <div>
            <Tooltip placement="top" title="Clone">
              <Button
                icon={<CopyOutlined />}
                onClick={() => editDrawer(record, "Clone")}
                children={undefined}
                disabled={!hasPermission("kitmaintenance_clone")}
              ></Button>
            </Tooltip>
          </div>

          {record?.ProcessStatus === 3 || record?.ProcessStatus === 4 ? (
            <Tooltip placement="top" title="Kit Details">
              <Button
                style={{ marginLeft: "20px" }}
                icon={<SearchOutlined />}
                children={undefined}
                onClick={() => editDrawer(record, "Details")}
              ></Button>
            </Tooltip>
          ) : (
            <>
              <Tooltip placement="top" title="Edit">
                <Button
                  icon={<EditOutlined />}
                  style={{ marginLeft: "20px" }}
                  children={undefined}
                  onClick={() => editDrawer(record, "Edit")}
                  disabled={!hasPermission("kitmaintenance_edit")}
                ></Button>
              </Tooltip>
              <div>
                <Tooltip placement="top" title="Delete">
                  <Button
                    style={{ marginLeft: "20px" }}
                    icon={<DeleteOutlined />}
                    onClick={() => deleteModal(record)}
                    children={undefined}
                    danger={true}
                    disabled={!hasPermission("kitmaintenance_delete")}
                  ></Button>
                </Tooltip>
              </div>
            </>
          )}

          {record?.ProcessStatus === 3 && record?.IsArchived && (
            <Tooltip placement="top" title="Unarchive">
              <Button
                style={{ marginLeft: "20px" }}
                icon={<UpSquareOutlined />}
                children={undefined}
                onClick={() => archiveModal(record)}
                danger={true}
              ></Button>
            </Tooltip>
          )}
          {record?.ProcessStatus === 3 && !record?.IsArchived && (
            <Tooltip placement="top" title="Archive">
              <Button
                style={{ marginLeft: "20px" }}
                icon={<DownSquareOutlined />}
                children={undefined}
                onClick={() => archiveModal(record)}
                danger={true}
                disabled={!hasPermission("kitmaintenance_archive")}
              ></Button>
            </Tooltip>
          )}
        </div>
      );
    },
  };
  columns = insertAt(columns, 7, 0, actionColumn);

  // frame data table filters
  const collection: any | null | undefined = [];
  const modal: any | null | undefined = [];
  const color: any | null | undefined = [];
  const frameType: any | null | undefined = [];
  frameData?.data?.map((row: any) => {
    collection.push({ text: row.Collection, value: row.Collection });
    modal.push({ text: row.Modal, value: row.Modal });
    color.push({ text: row.Color, value: row.Color });
    frameType.push({ text: row.FrameType, value: row.FrameType });
  });

  let collectionName = collection.filter(
    (ele: any, ind: any) =>
      ind ===
      collection.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  let modalName = modal.filter(
    (ele: any, ind: any) =>
      ind ===
      modal.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  let colorName = color.filter(
    (ele: any, ind: any) =>
      ind ===
      color.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  let frameTypeName = frameType.filter(
    (ele: any, ind: any) =>
      ind ===
      frameType.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  const collectionColumns = {
    title: "Collection",
    dataIndex: "Collection",
    sorter: (a: { Collection: string }, b: { Collection: any }) =>
      a.Collection?.localeCompare(b.Collection),
    sortDirections: ["ascend", "descend"],
    filterMultiple: true,
    width: 150,
  };
  const modalColumns = {
    title: "Model",
    dataIndex: "Model",
    sorter: (a: { Model: string }, b: { Model: any }) =>
      a.Model?.localeCompare(b.Model),
    sortDirections: ["ascend", "descend"],
    filterMultiple: true,
    width: 150,
  };
  const colorColumns = {
    title: "Color",
    dataIndex: "Color",
    sorter: (a: { Color: string }, b: { Color: any }) =>
      a.Color?.localeCompare(b.Color),
    sortDirections: ["ascend", "descend"],
    filterMultiple: true,
    width: 120,
  };
  const frameTypeColumns = {
    title: "Frame Type",
    dataIndex: "FrameType",
    sorter: (a: { FrameType: string }, b: { FrameType: any }) =>
      a.FrameType?.localeCompare(b.FrameType),
    sortDirections: ["ascend", "descend"],
    filterMultiple: true,
    width: 120,
  };
  let frameColumns = insertAt(KITS_FRAME_COLUMN, 0, 0, collectionColumns);
  frameColumns = insertAt(frameColumns, 1, 0, modalColumns);
  frameColumns = insertAt(frameColumns, 2, 0, colorColumns);
  frameColumns = insertAt(frameColumns, 6, 0, frameTypeColumns);
  const FrameActionColumn = {
    title: "action",
    dataIndex: "operation",
    align: "center",
    width: 100,
    render: (_: any, record: any) => {
      return (
        <div className="d-flex w-100">
          <Button
            style={{ marginLeft: "20px" }}
            icon={<DeleteOutlined />}
            onClick={() => deleteModal(record)}
            children={undefined}
            danger={true}
          ></Button>
        </div>
      );
    },
  };
  frameColumns = insertAt(frameColumns, 9, 0, FrameActionColumn);

  return { columns, frameColumns };
}
