import { useSelector } from "react-redux";

export function useRolePermission() {
  const roles = useSelector((state) => state) as any;
  const hasPermission = (permission: string) => {
    const roleName = roles?.MenuPermissionData?.role;
    if (permission === undefined || (permission === null && roleName == null)) {
      return false;
    } else {
      return roles?.MenuPermissionData?.permissions[roleName].scopes.includes(
        permission
      );
    }
  };
  return {
    hasPermission,
  };
}

export default useRolePermission;
