import { MENU_PERMISSIONS } from "src/util/constants/permissions.constants";

const initialState = {
  role: null,
  permissions: null,
};

const permissionReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case "SET_ROLE":
      return {
        ...state,
        role: payload,
        permissions: MENU_PERMISSIONS,
      };
    default:
      return {
        ...state,
      };
  }
};

export default permissionReducer;
