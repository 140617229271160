import { Divider, Modal, Spin, Switch } from "antd";
import { MODULE_KEY, fetchKitsMaintenance } from "./actions";
import GenericTable from "src/components/common/Table/GenericTable";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import { PlusOutlined } from "@ant-design/icons";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useState } from "react";
import filterColumns from "./filterColumns";
import { getKitData, deleteKit, archiveKit } from "./sagas";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import { useMsal } from "@azure/msal-react";
import Label from "src/components/common/Label/Label";
import useRolePermission from "src/hooks/useRolePermission";

function KitMaintenance() {
  const { accounts }: any = useMsal();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [isKitProcessDraft, setIsKitProcessDraft] = useState(false);
  const [open, setOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [archiveData, setArchiveData] = useState();
  const [isKitArchived, setIsKitArchived] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const { hasPermission } = useRolePermission();

  useEffect(() => {
    dispatch(fetchKitsMaintenance(showArchived));
  }, []);

  useEffect(() => {
    dispatch(fetchKitsMaintenance(showArchived));
  }, [showArchived]);

  const editDrawer = (record: any, type: string) => {
    const res = getKitData(record?.KitId);
    const pathName = type === "Details" ? "/kitDetailPage" : "/newKit";
    res.then((response: any) => {
      history(pathName, {
        state: { ...response, actionType: type },
      });
    });
  };
  const deleteModal = (record: any) => {
    setOpen(true);
    setDeleteData(record?.KitId);
    setIsKitProcessDraft(record?.ProcessStatus == 2);
  };

  const archiveModal = (record: any) => {
    setArchiveOpen(true);
    setArchiveData(record?.KitId);
    setIsKitArchived(!record?.IsArchived);
  };

  let filterValues = filterColumns(
    deleteModal,
    editDrawer,
    filteredData,
    archiveModal
  );
  const handleDelete = async (e: any) => {
    setConfirmLoading(true);
    try {
      const res = await deleteKit({
        CreatedBy: accounts[0]?.username,
        KitId: deleteData,
      });
      if (res) {
        showSuccessNotification(res);
        dispatch(fetchKitsMaintenance(showArchived));
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
    }
    setConfirmLoading(false);
    handleCancel();
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const handleArchive = async (e: any) => {
    setConfirmLoading(true);
    try {
      const res = await archiveKit({
        CreatedBy: accounts[0]?.username,
        KitId: archiveData,
        IsArchived: isKitArchived,
      });

      if (res) {
        showSuccessNotification(res);
        dispatch(fetchKitsMaintenance(showArchived));
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data.error.messages[0]);
    }
    setConfirmLoading(false);
    handleCancel();
    setArchiveOpen(false);
  };

  const handleArchiveCancel = () => {
    setArchiveOpen(false);
  };

  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Kit Maintenance</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex gap-2 justify-content-end">
            <Label
              label="Show Archived"
              className="flex-row align-items-center fs-6 gap-3"
            >
              <Switch
                size="small"
                checked={showArchived}
                onChange={(flag) => {
                  setShowArchived(flag);
                }}
              />
            </Label>
            <div>
              {hasPermission("kitmaintenance_add") && (
                <Button
                  size="middle"
                  icon={<PlusOutlined />}
                  style={{ marginRight: "10px" }}
                  ant-click-animating-without-extra-node="true"
                >
                  {" "}
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    href="/newKit"
                  >
                    Add New Kit
                  </a>
                </Button>
              )}
            </div>
          </div>
        </TableFilter>
        <GenericTable
          rowkey="KitId"
          columns={filterValues.columns}
          moduleKey={MODULE_KEY}
          loading={confirmLoading}
          xScroll={1000}
          yScroll={"calc(90vh - 17.5em)"}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100"],
          }}
          filteredData={true}
          setFilteredData={setFilteredData}
        />
        <Modal
          title="Delete Kit Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={open}
          onOk={(e) => handleDelete(e)}
          onCancel={handleCancel}
        >
          <span>
            {isKitProcessDraft
              ? "Process for this kit has been drafted. Are you sure you wish to delete this Kit and related data?"
              : "Are you sure you wish to delete this Kit?"}{" "}
          </span>
        </Modal>
        <Modal
          title="Archive Kit Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={archiveOpen}
          onOk={(e) => handleArchive(e)}
          onCancel={handleArchiveCancel}
        >
          <span>
            {!isKitArchived
              ? "Are you sure you wish to unarchive this Kit?"
              : "Are you sure you wish to archive this Kit?"}{" "}
          </span>
        </Modal>
      </Spin>
    </>
  );
}
export default KitMaintenance;
