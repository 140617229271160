import { Card, Divider } from "antd";
import SIGN_IN_LOGO from "../../assets/images/sign-in-logo.svg";
import styles from "./unauthorized.module.scss";

function Unauthorized() {
  return (
    <>
      <Divider />
      <Card style={{ width: 1000, textAlign: "center", margin: "20px auto" }}>
        <img src={SIGN_IN_LOGO} width={120} height={120} />
        <h2>You dont have permission to this page!</h2>
      </Card>
      <img alt="logo" className={styles["logo"]} src={"./images/logo.svg"} />
    </>
  );
}
export default Unauthorized;
